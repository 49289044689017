<template>
  <div class="lad-confidence">
    <el-container>
      <el-main>
        <h2 class="lad-confidence--title">{{ $t(`${base}.title`) }}</h2>

        <LazyLoading
          :key="`confidence`"
          :loaded="lazy.confidence"
          @loaded="(e) => (lazy.confidence = e)"
        >
          <Swiper
            v-if="lazy.confidence"
            ref="confidenceSwiper"
            :options="swiperOption"
          >
            <SwiperSlide v-for="(brand, index) in brands" :key="index">
              <div>
                <a class="confidence-image" :href="brand.link" target="_blank">
                  <svg-icon
                    :name="brand.item"
                    width="10em"
                    class="sprite-icons"
                  />
                </a>
              </div>
            </SwiperSlide>
            <div slot="pagination" class="swiper-pagination"></div>
          </Swiper>
        </LazyLoading>

        <template v-if="withCities">
          <h2 class="lad-confidence--title">{{
            $t(`${base}.best_cities.subtitle`)
          }}</h2>

          <LazyHydrate when-visible>
            <el-row class="row-best-cities">
              <el-col
                class="wrapper-best-cities"
                :class="{ 'is-closed': !openItems }"
              >
                <div
                  v-for="[region, items] in Object.entries(cities)"
                  :key="region"
                >
                  <h3>{{ $t(`${base}.best_cities.region.${region}`) }}</h3>

                  <ul class="list-unstyled">
                    <li v-for="city in items" :key="city.slug">
                      <h4>
                        <nuxt-link
                          :to="{
                            name: 'search-type',
                            params: {
                              type: $t('router.search_type.type.city'),
                              value: city.slug,
                              tab: $t('router.search_type.tab.tours'),
                            },
                          }"
                        >
                          {{
                            $t(`${base}.best_cities.label`, { name: city.name })
                          }}
                        </nuxt-link>
                      </h4>
                    </li>
                  </ul>
                </div>
              </el-col>

              <el-col>
                <el-button type="text" @click="openItems = !openItems">{{
                  $t(`${base}.best_cities.${itemsText}`)
                }}</el-button>
              </el-col>
            </el-row>
          </LazyHydrate>
        </template>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CacheComponentMixin from '@/utils/mixin/cache-components'

export default {
  name: 'LadConfidence',
  mixins: [CacheComponentMixin],
  props: {
    // eslint-disable-next-line vue/no-boolean-default
    withCities: { type: Boolean, default: true },
  },
  data() {
    return {
      base: 'home.confidence',
      brands: [
        {
          link: 'https://www.youtube.com/watch?v=VjJM8RjUo4A',
          item: 'confidence/phocuswright',
        },
        {
          link:
            'https://cdn-3.expansion.mx/14/e1/16b433c14f94bb21f64ca10e900f/expansion-1285-compressed.pdf',
          item: 'confidence/expansion',
        },
        {
          link:
            'https://finance.yahoo.com/news/budget-still-visit-mexico-cheaply-110000573.html',
          item: 'confidence/yahoo',
        },
        {
          link: 'https://www.endeavor.org.mx/',
          item: 'confidence/endeavor',
        },
        {
          link:
            'https://contxto.com/es/mexico-es/startup-viajes-localadventures-expande-colombia/',
          item: 'confidence/contexto',
        },
        {
          link:
            'https://arival.travel/39-otas-for-multi-day-tours-custom-trips/',
          item: 'confidence/arival',
        },
        {
          link:
            'https://www.ngenespanol.com/traveler/cuanto-cuesta-viajar-a-egipto-desde-mexico-cuanto-dinero-necesito/amp/',
          item: 'confidence/national-geographic',
        },
        {
          link:
            'https://freeman.la/nace-stuntshare-una-plataforma-para-encontrar-guias-empresas-y-equipo-de-aventura/',
          item: 'confidence/freeman',
        },
        {
          link:
            'https://www.eluniversal.com.mx/destinos/ya-puedes-conseguir-recorridos-por-whatsapp/',
          item: 'confidence/universal',
        },
        {
          link:
            'https://www.merca20.com/sitio-del-dia-una-plataforma-para-practicar-turismo-con-total-flexibilidad/',
          item: 'confidence/merca-two',
        },
        {
          link:
            'https://www.adventuretravelnews.com/can-ai-predict-your-next-international-adventure ',
          item: 'confidence/adventure',
        },
        {
          link:
            'https://www.elfinanciero.com.mx/tech/puede-la-ia-predecir-tu-siguiente-aventura-esta-agencia-cree-que-si',
          item: 'confidence/financiero',
        },
        {
          link:
            'https://www.forbes.com.mx/cambian-de-hacer-tours-a-conciertos-para-organizar-turismo-de-vacunas/',
          item: 'confidence/forbes',
        },
        {
          link: 'https://hf.cx/local-adventures/',
          item: 'confidence/hf',
        },
        {
          link:
            'https://localadventures.mx/blog/2021/07/daniel-pena-ceo-de-localadventures-habla-sobre-los-viajes-en-tiempos-de-pandemia-en-la-revista-milenio/',
          item: 'confidence/milenio',
        },
        {
          link: '#',
          item: 'confidence/numa',
        },
        {
          link:
            'https://theshoppers.com/es/fun/experiencias-aventuras-extremas-localadventures/',
          item: 'confidence/the-shoppers',
        },
        {
          link:
            'https://wildentrepreneur.org/asi-apuesta-localadventures-para-la-recuperacion-del-turismo-tras-la-pandemia/',
          item: 'confidence/wild',
        },
      ],
      current: 0,
      openItems: false,
      lazy: { confidence: false },
      swiperOption: {
        slidesPerView: 'auto',
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        on: {
          slideChange: () => {
            const swiper = this.$refs.confidenceSwiper.$swiper

            if (!!swiper && swiper.activeIndex && !isNaN(swiper.activeIndex)) {
              this.current = swiper.activeIndex % this.brands.length
            }
          },
          // slideChange: () => {
          //   this.current = this.swiper.activeIndex % this.brands.length
          // },
        },
      },
    }
  },
  computed: {
    ...mapState('settings', {
      cities: (state) => state.popular_cities,
    }),
    itemsText() {
      return this.openItems ? 'show_less' : 'show_more'
    },
  },
}
</script>

<style lang="scss">
.lad-confidence {
  background-color: white;

  &--title {
    text-align: center;
    margin-bottom: 10px;
  }

  .el-main {
    .swiper-slide {
      min-width: 200px;
      width: auto;
      margin: 0 20px;
    }

    .confidence-image {
      text-align: center;
    }

    .swiper-pagination {
      position: relative;
      margin-top: 20px;
      bottom: inherit;
    }
  }

  .wrapper-lazy-loading {
    margin-bottom: 40px;
  }

  .row-best-cities {
    @media screen and (max-width: 465px) {
      margin: 0 20px;
    }
  }

  .wrapper-best-cities {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;
    margin-top: 40px;
    padding: 0 10px;

    p,
    h3 {
      color: black;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 21.29px;
      text-transform: uppercase;
    }

    h3 {
      margin: 0 0 1em;
    }

    h4 {
      font-size: 14px;
      font-weight: 400;
      margin: 0;
    }

    .list-unstyled {
      li {
        padding-left: 0;

        a {
          color: black;
        }
      }
    }

    & + div {
      .el-button {
        margin-left: 10px;
      }
    }

    &.is-closed .list-unstyled > li:nth-child(n + 6) {
      display: none;
    }

    @media screen and (max-width: 465px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 370px) {
      grid-gap: 10px;
    }
  }
}
</style>
